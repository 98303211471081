import React from "react"
import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"

const FaqsHeader = () => {
  const FaqItems = [
    { title: "1. How does it work? ", description: "Lorem Ipsum" },
    {
      title: "2. How does it work?",
      description:
        "To opt-out, navigate to your social profile and click on the Op-out button. Your investment activities will be hidden from public, but your regular text posts will still remain visible.",
    },
    {
      title: "3. How does it work?",
      description:
        "To opt-out, navigate to your social profile and click on the Op-out button. Your investment activities will be hidden from public, but your regular text posts will still remain visible.",
    },
    {
      title: "4. How does it work?",
      description:
        "To opt-out, navigate to your social profile and click on the Op-out button. Your investment activities will be hidden from public, but your regular text posts will still remain visible.",
    },
    {
      title: "5. How does it work?",
      description:
        "To opt-out, navigate to your social profile and click on the Op-out button. Your investment activities will be hidden from public, but your regular text posts will still remain visible.",
    },
    {
      title: "6. How does it work?",
      description:
        "To opt-out, navigate to your social profile and click on the Op-out button. Your investment activities will be hidden from public, but your regular text posts will still remain visible.",
    },
  ]

  const textColor = useColorModeValue("#171F2B", "white")
  const textColor2 = useColorModeValue("#fd6d3c", "#f26d3c")

  return (
    <Flex
      direction={["column", "row"]}
      justifyContent={"center"}
      w={"100%"}
      h={"100%"}
      pt={15}
      pb={15}
    >
      <Box mr={15} w={["100%", "40%"]}>
        <Text fontSize={"xl"} textAlign={"center"} color={textColor2}>
          FAQS
        </Text>
      </Box>

      <Box w={["100%", "50%"]} pr={[0, 20]}>
        <UnorderedList listStyleType={"none"}>
          {FaqItems.map((data) => {
            return (
              <ListItem p={5}>
                <Text fontWeight={800} color={textColor2} fontSize={"md"}>
                  {data.title}
                </Text>
                <Text color={textColor}>{data.description}</Text>
              </ListItem>
            )
          })}
        </UnorderedList>
      </Box>
    </Flex>
  )
}

export default FaqsHeader
