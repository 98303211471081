import React from "react"
import { Box, useColorModeValue } from "@chakra-ui/react"
import FaqsHeader from "~components/faqs/faqs-header"

const FaqsPage = () => {
  const bgColor = useColorModeValue("white", "#171F2B")

  return (
    <Box bgColor={bgColor} w={"100%"} m={0}>
      <FaqsHeader />
    </Box>
  )
}

export default FaqsPage
